.wrapper {
  padding: 1rem 1rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}

.informationRow {
  margin-bottom: 0.5rem;
}

.activityRow {
  margin-bottom: 0rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.modalHeader {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.modalTitleWrapper {
  color: var(--brand-black);
}

.modalTitleWrapper h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.modalHeader button {
  align-self: flex-start;
}

.modalHeaderNounBRImgWrapper {
  width: 150px;
  height: 150px;
}

.currentBidCol {
  border-right: 1px solid #79809c49;
  margin-left: 6px;
  margin-top: 6px;
}

.auctionTimerCol {
  padding-left: 0rem;
}

.section h4 {
  font-family: 'PT Root UI';
  font-size: 17px;
  line-height: 25px;
}

.section h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 992px) {
  h1 {
    padding-top: 12px;
  }

  .wrapper {
    padding: 1rem;
  }

  .auctionTimerCol {
    margin-left: 6px;
    margin-top: 6px;
    padding-left: 0rem;
  }

  .currentBidCol {
    border-right: 0px;
    padding-left: 0rem;
  }

  .fomoNounsBRLink {
    padding-left: 14px;
  }
}

@media (max-width: 400px) {
  .fomoNounsBRLink {
    font-size: 14px;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .colAlignCenter {
    display: flex;
    align-items: center;
  }
}

.fomoNounsBRLink {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;
}

.fomoNounsBRLink svg {
  opacity: 0.6;
}

.fomoNounsBRLink a,
.fomoNounsBRLink:active a {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  padding-left: 0.5rem;
  text-decoration: none;
  opacity: 1;
  color: rgb(95, 95, 95);
}

.fomoNounsBRLink:hover,
.fomoNounsBRLink:hover a {
  text-decoration: none;
  color: black;
  opacity: 1;
}
